@charset "UTF-8";
/**
 * General reset
 */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap");
[v-cloak] {
  display: none;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  overflow-y: scroll;
  /**
   * Custom scrollbar
   */
  /**
   * Custom selection color
   */
}
html::-webkit-scrollbar {
  width: 0.8rem;
}
html::-webkit-scrollbar-thumb {
  background-color: #002EA6;
  outline: none;
  border-radius: 0;
}
html ::selection {
  background-color: #2397c5;
  color: #fff;
}

html,
body {
  font-size: 62.5%;
  margin: 0;
  padding: 0;
}

section {
  position: relative;
}
section > span {
  position: absolute;
  top: -100px;
}

strong {
  font-weight: bold;
}

/**
 * Bootstrap reset
 */
img {
  -webkit-user-drag: none;
}
img.px {
  image-rendering: pixelated;
}
img.aa {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

a {
  text-decoration: none;
  color: #002EA6;
}

ul {
  list-style: none;
}

/* Animations */
/* Text-colors */
.text-white {
  color: #fff;
}

.text-grey-300 {
  color: #676767;
}

.alert {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 1000;
  width: 500px;
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid #cf7a7a;
  box-shadow: 2px 13px 15px rgba(0, 0, 0, 0.3);
}
.alert .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  background-color: transparent;
  border: 0;
  color: #cf7a7a;
  text-align: center;
  line-height: 16px;
  font-size: 32px;
  cursor: pointer;
}
.alert .close:hover {
  color: #a73c3c;
}

body {
  font-family: "Quicksand", sans-serif;
  font-size: 1.4rem;
}
@media screen and (min-width: 576px) {
  body {
    font-size: 1.6rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 900;
}

h1,
h2,
h3,
h4,
p {
  transition: 0.3s ease-in-out;
}
h1.--100, h1 .--100,
h2.--100,
h2 .--100,
h3.--100,
h3 .--100,
h4.--100,
h4 .--100,
p.--100,
p .--100 {
  font-weight: 100;
}
h1.--200, h1 .--200,
h2.--200,
h2 .--200,
h3.--200,
h3 .--200,
h4.--200,
h4 .--200,
p.--200,
p .--200 {
  font-weight: 200;
}
h1.--300, h1 .--300,
h2.--300,
h2 .--300,
h3.--300,
h3 .--300,
h4.--300,
h4 .--300,
p.--300,
p .--300 {
  font-weight: 300;
}
h1.--400, h1 .--400,
h2.--400,
h2 .--400,
h3.--400,
h3 .--400,
h4.--400,
h4 .--400,
p.--400,
p .--400 {
  font-weight: 400;
}
h1.--700, h1 .--700,
h2.--700,
h2 .--700,
h3.--700,
h3 .--700,
h4.--700,
h4 .--700,
p.--700,
p .--700 {
  font-weight: 700;
}
h1.--900, h1 .--900,
h2.--900,
h2 .--900,
h3.--900,
h3 .--900,
h4.--900,
h4 .--900,
p.--900,
p .--900 {
  font-weight: 900;
}

h1 {
  font-size: 2.8rem;
}
@media screen and (min-width: 576px) {
  h1 {
    font-size: 3.6rem;
  }
}
@media screen and (min-width: 1200px) {
  h1 {
    font-size: 4.8rem;
  }
}

h2 {
  font-size: 3.6rem;
}

h3 {
  font-size: 2.4rem;
}

p {
  font-size: 1.4rem;
  font-weight: 300;
}
@media screen and (min-width: 576px) {
  p {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 1200px) {
  p {
    font-size: 1.8rem;
  }
}

.text--white {
  color: #fff !important;
}

.bg--white {
  background-color: #fff !important;
}

.text--black {
  color: #000 !important;
}

.bg--black {
  background-color: #000 !important;
}

.text--grey-50 {
  color: #e0e0e0 !important;
}

.bg--grey-50 {
  background-color: #e0e0e0 !important;
}

.text--grey-90 {
  color: #A3A3A3 !important;
}

.bg--grey-90 {
  background-color: #A3A3A3 !important;
}

.text--grey-100 {
  color: #8F8F8F !important;
}

.bg--grey-100 {
  background-color: #8F8F8F !important;
}

.text--grey-200 {
  color: #898888 !important;
}

.bg--grey-200 {
  background-color: #898888 !important;
}

.text--grey-300 {
  color: #676767 !important;
}

.bg--grey-300 {
  background-color: #676767 !important;
}

.text--blue {
  color: #002EA6 !important;
}

.bg--blue {
  background-color: #002EA6 !important;
}

.text--blue-50 {
  color: #3EB0DD !important;
}

.bg--blue-50 {
  background-color: #3EB0DD !important;
}

.text--blue-300 {
  color: #265CE9 !important;
}

.bg--blue-300 {
  background-color: #265CE9 !important;
}

.text--red {
  color: #CD2241 !important;
}

.bg--red {
  background-color: #CD2241 !important;
}

.text--green-btn {
  color: rgba(35, 207, 92, 0.9803921569) !important;
}

.bg--green-btn {
  background-color: rgba(35, 207, 92, 0.9803921569) !important;
}

.card {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 16px;
}
.card .card-content {
  padding: 24px;
}
.card .card-actions {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid rgba(197, 197, 197, 0.8);
  margin-top: 24px;
  text-align: right;
}

.modal-theme .modal-dialog .modal-content {
  font-size: 1.6rem;
  padding: 1.6rem;
  border-radius: 1rem;
}

.swiper {
  height: 142px;
}
.swiper .swiper-slide {
  background-size: cover;
  background-repeat: no-repeat;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper .swiper-slide .swiper-img {
  object-fit: cover;
  width: 100%;
}
.swiper .swiper-slide .swiper-title-wrapper .title {
  font-size: 4rem;
  font-family: "hk-bold-italic";
}
.swiper .swiper-slide .swiper-title-wrapper .subtitle-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 4rem;
  border-left: 2px solid #FC4547;
  padding: 0.3rem 1rem;
}
.swiper .swiper-button-prev, .swiper .swiper-button-next {
  padding: 5rem 2.5rem;
}
.swiper .swiper-button-prev::after, .swiper .swiper-button-next::after {
  font-size: 25px;
}
@media (max-width: 991.98px) {
  .swiper .swiper-button-prev::after, .swiper .swiper-button-next::after {
    visibility: hidden;
  }
}
.swiper .swiper-button-prev {
  left: 0;
  border-radius: 0 5px 5px 0;
}
.swiper .swiper-button-next {
  right: 0;
  border-radius: 5px 0 0 5px;
}

.swiper.swiperMobileTableVersion {
  background-color: red;
}

.map-wrapper {
  width: 100%;
  height: 350px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.map-wrapper .map {
  width: 100%;
  height: 100%;
  border: 0;
}
.map-wrapper .--overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 2.4rem;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: grid;
  align-items: center;
  pointer-events: none;
  cursor: default;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  z-index: 1001;
}

@media screen and (min-width: 576px) {
  .map-wrapper {
    aspect-ratio: 3/2;
    width: 100%;
    height: 50vh;
  }
}
@media screen and (min-width: 1400px) {
  .map-wrapper {
    aspect-ratio: 30/9;
    width: 100%;
    height: 60vh;
  }
}
#home {
  color: inherit;
}

.swiper {
  width: 100%;
  height: 250px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-como-contratar {
  background: linear-gradient(107.23deg, #d33f44 -8.03%, #290563 57.48%, #1f42fa 136.54%);
  padding-top: 8rem;
  padding-bottom: 12rem;
}
.section-como-contratar .description {
  margin-top: 3.5rem;
}
.section-como-contratar .description h3 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 114%;
  text-transform: uppercase;
  color: #ffffff;
}
@media (max-width: 575.98px) {
  .section-como-contratar .description h3 {
    font-size: 30px;
  }
}
.section-como-contratar .description p {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 168.5%;
  /* or 25px */
  color: #ffffff;
}
@media (max-width: 575.98px) {
  .section-como-contratar .description .button-wrapper {
    margin-top: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 2rem;
  }
}
.section-como-contratar .description a {
  background: #265ce9;
  color: #ffffff;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 168.5%;
  padding: 1.5rem 3.5rem;
  margin-top: 2rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 4px solid #265ce9;
}
.section-como-contratar .description a:hover {
  background: #1f42fa;
  border: 4px solid #1f42fa;
}
.section-como-contratar .description a:focus {
  border: 4px solid #ffffff;
}
.section-como-contratar .feature {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.section-como-contratar .feature .number-wrapper {
  width: 86px;
  text-align: center;
}
.section-como-contratar .feature h4 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  color: transparent;
  font-size: 150px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(241, 238, 238, 0.3882352941);
  transform: translateX(40%);
  z-index: -1;
}
@media (max-width: 767.98px) {
  .section-como-contratar .feature h4 {
    transform: translateX(0);
    font-size: 100px;
  }
}
.section-como-contratar .feature .img-wrapper {
  background-color: #265ce9;
  width: 109px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 2;
}
.section-como-contratar .feature p {
  width: 250px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 114%;
  color: #ffffff;
}
@media (max-width: 1024px) {
  .section-como-contratar .feature p {
    width: 160px;
  }
}
@media (max-width: 575.98px) {
  .section-como-contratar .feature p {
    font-size: 14px;
  }
}

.section-plains .card-plain {
  transform: translateY(-80px);
}
.section-plains .card-plain:nth-child(1) h3 {
  color: #b1510f;
}
.section-plains .card-plain:nth-child(2) h3 {
  color: #9598a0;
}
.section-plains .card-plain:nth-child(3) h3 {
  color: #d7a700;
}
.section-plains .card-plain:nth-child(4) h3 {
  color: #1f1f1f;
}
.section-plains .card-plain:nth-child(5) h3 {
  color: #00397b;
}
.section-plains .card-plain h3 {
  border-top: 1px solid #c2c2c2;
  padding-block: 1.5rem;
  margin-block: 1rem;
  font-family: "Quicksand";
  border-bottom: 1px solid #c2c2c2;
}
.section-plains .card-plain ul {
  list-style-image: url("/img/icons/check.svg");
  padding-inline: 2rem;
}
.section-plains .card-plain ul li {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 168.5%;
  color: #747474;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.section-plains .card-plain .conteudo-text {
  padding: 16px;
  min-height: 90%;
  margin-top: -12px;
  border: 1px solid #e4e4e4;
}
@media (max-width: 568px) {
  .section-plains .card-plain .conteudo-text {
    min-height: auto;
    margin-bottom: 12px;
  }
}
.section-plains .plans-footer {
  background-color: #f6f6f6;
}

/* Sessão de planos VERSÃO DESKTOP */
.section-plains {
  transform: translateY(-52px);
}
@media (max-width: 768.98px) {
  .section-plains {
    transform: translateY(0);
    padding-top: 2rem;
  }
}
.section-plains .aviso {
  padding: 10px;
  background-color: #f8f8f8;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.section-plains .aviso i {
  color: #79ba5b;
}
.section-plains .aviso p {
  color: #79ba5b;
  font-weight: 400;
  font-family: "Quicksand";
  margin-bottom: 0;
}
.section-plains .plains-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
}
.section-plains .plains-grid .color-bronze {
  color: #b1510f;
}
.section-plains .plains-grid .color-prata {
  color: #747474;
}
.section-plains .plains-grid .color-gold {
  color: #d7a700;
}
.section-plains .plains-grid .color-empresarial {
  color: #1f1f1f;
}
.section-plains .plains-grid .color-grupo {
  color: #00397b;
}
.section-plains .plains-grid .ghost {
  height: 262px;
}
@media (max-width: 1199.98px) {
  .section-plains .plains-grid .ghost {
    height: 245px;
  }
}
@media (max-width: 991.98px) {
  .section-plains .plains-grid .ghost {
    height: 203px;
  }
}
.section-plains .plains-grid .title {
  background-color: rgba(194, 194, 194, 0.1803921569);
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-plains .plains-grid .title h3 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}
@media (max-width: 768.98px) {
  .section-plains .plains-grid .title h3 {
    font-size: 16px;
  }
}
.section-plains .plains-grid .subtitle {
  background-color: rgba(194, 194, 194, 0.1803921569);
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-plains .plains-grid .subtitle p {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  color: #676767;
}
.section-plains .plains-grid .plains-column-descriptions {
  display: flex;
  flex-direction: column;
}
.section-plains .plains-grid .plains-column-descriptions > p {
  padding: 10px;
  border: 1px solid #d9d9d9;
  margin-bottom: 0;
  height: 100px;
  display: flex;
  align-items: center;
}
@media (max-width: 768.98px) {
  .section-plains .plains-grid .plains-column-descriptions > p {
    height: 70px;
    font-size: 11px;
  }
}
.section-plains .plains-grid .plains-column-default {
  text-align: center;
}
@media (max-width: 991.98px) {
  .section-plains .plains-grid .plains-column-default img {
    width: 103px;
  }
}
.section-plains .plains-grid .plains-column-default > div {
  padding: 10px;
  border: 1px solid #d9d9d9;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768.98px) {
  .section-plains .plains-grid .plains-column-default > div {
    height: 70px;
  }
}
.section-plains .plains-grid .plains-column-default > div p.p {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  color: #5c5c5c;
}
.section-plains .plains-grid .plains-column-default > div.check {
  background-color: #f3ffed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-plains .plains-grid .plains-column-default > div.check i {
  color: #79ba5b;
  font-size: 25px;
}
.section-plains .plains-grid .plains-column-default .valor-plano {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
}
.section-plains .plains-grid .plains-column-default .valor-plano .title {
  font-weight: 400;
  background-color: transparent;
  margin-bottom: 0;
}
.section-plains .plains-grid .plains-column-default .valor-plano .subtitle {
  font-weight: 700;
  background-color: transparent;
}
.section-plains .plains-grid .plains-column-default .btn-contratar-plano {
  width: 100%;
  background-color: #79ba5b;
  color: white;
  font-family: "Quicksand";
  border: none;
  padding: 10px;
  font-weight: 700;
  border: 1px solid #d9d9d9;
  transition: all 0.3s ease-in-out;
}
.section-plains .plains-grid .plains-column-default .btn-contratar-plano:hover {
  background-color: #3f7625;
}

/* Sessão de planos VERSÃO Mobile */
/* Sessão do blog  */
.home-blog-section {
  height: fit-content;
  padding: 8rem 0 8rem 0;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-blog-section .home-blog-section-title-wrapper {
  width: 75%;
  text-align: start;
}
.home-blog-section .home-blog-section-title-wrapper h1 {
  color: #676767;
  font-size: 2.5rem;
}
.home-blog-section .blog-posts-section {
  margin-top: 2rem;
  width: 75%;
}
.home-blog-section button {
  margin-top: 8rem;
  background-color: #255ce8;
  outline: none;
  border: none;
  color: white;
  font-weight: bold;
  padding: 1.5rem 2rem 1.5rem 2rem;
}

/* Sessão de posts do instagram */
.section-instagram {
  padding: 60px 0;
  background-color: #F6F9FF;
}
.section-instagram img {
  width: 100px;
}
.section-instagram p {
  font-size: 2rem;
  color: rgb(103, 103, 103);
  font-weight: bold;
}

.contato-anchor {
  position: absolute;
  z-index: -999;
  transform: translateY(-15rem);
}

@media (max-width: 572px) {
  #cartao:target {
    margin-top: 75px;
  }
  #beneficios:target {
    margin-top: 140px;
  }
  #redes:target {
    margin-top: 75px;
  }
  #contratar:target {
    margin-top: 120px;
  }
  #plainsMobile:target {
    padding-top: 25px;
  }
  #parcerias:target {
    margin-top: 85px;
  }
  #trabalheConosco:target {
    margin-top: 75px;
  }
  #contato:target {
    margin-top: 75px;
  }
}
/* Sessão de header do blog */
.header-section {
  max-width: 75%;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 3.5rem;
  color: #676767;
}
.header-section h1 {
  font-size: 3rem;
  font-weight: bold;
}
.header-section .filter-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.header-section .filter-wrapper h2 {
  font-size: 2rem;
  font-weight: lighter;
}
.header-section .filter-wrapper div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.header-section .filter-wrapper select {
  outline: none !important;
  border: none !important;
  font-size: 1.25rem;
  padding: 1.15rem;
}
@media only screen and (max-width: 1010px) {
  .header-section {
    margin-top: 10rem;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin: 1.25rem 0 1.25rem 0;
}

.blog-section-anchor {
  position: absolute;
  z-index: -10;
  transform: translateY(-32rem);
}

.blog-section {
  max-width: 75%;
  margin: 0 auto 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 8rem;
  align-items: center;
  margin-top: 5rem;
}
.blog-section .blog-post {
  height: fit-content;
  margin: 0 auto;
}
.blog-section .blog-post img {
  height: 32rem;
  object-fit: cover;
}
.blog-section .blog-post .title-wrapper {
  width: 100%;
  margin-top: 2.3rem;
}
.blog-section .blog-post .title-wrapper span {
  font-size: 1.25rem;
  color: #676767;
}
.blog-section .blog-post .title-wrapper h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #676767;
}
.blog-section .blog-post p {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  font-size: 1.1rem;
  color: #7e7e7e;
}
.blog-section .blog-post .card-divider {
  margin-bottom: 0.75rem;
}
.blog-section .blog-post .read-more-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.blog-section .blog-post .read-more-wrapper img {
  height: 1.5rem;
  width: 1.5rem;
}
.blog-section .blog-post .read-more-wrapper span {
  font-size: 1.2rem;
  color: #255ce8;
}
.blog-section .blog-pagination {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  color: #676767;
  font-size: 1.75rem;
}
.blog-section .blog-pagination img {
  scale: 0.8;
}
.blog-section .blog-pagination div {
  display: flex;
  gap: 1.5rem;
}
.blog-section .blog-pagination .selected {
  color: #255ce8;
  font-weight: bold;
}
@media only screen and (max-width: 1000px) {
  .blog-section {
    gap: 2rem;
    margin-top: 0;
  }
}

/* Sessão da pagina de um post */
.post-section {
  max-width: 75%;
  margin: 0 auto 0 auto;
  margin-top: 5rem;
}
.post-section .image-wrapper {
  height: 40rem;
  width: 58rem;
  float: left;
  margin: 0 4.5rem 2.5rem 0;
}
.post-section .image-wrapper img {
  width: 58rem;
  height: 40rem;
  object-fit: cover;
}
@media only screen and (max-width: 1200px) {
  .post-section .image-wrapper img {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 1200px) {
  .post-section .image-wrapper {
    float: none;
    width: 100%;
    display: flex;
    margin-right: 0;
  }
}
.post-section h1 {
  font-size: 2.5rem;
  margin-bottom: 1.25rem;
  color: #676767;
}

.recomendations-section {
  max-width: 75%;
  margin: 0 auto 0 auto;
  margin-top: 12rem;
}
.recomendations-section h1 {
  font-size: 2.5rem;
  color: #676767;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 1600px) {
  .recomendations-section h1 {
    margin-top: 15rem;
  }
}
@media only screen and (min-width: 2150px) {
  .recomendations-section h1 {
    margin-top: 25rem;
  }
}
.recomendations-section .posts-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5rem;
  margin-bottom: 10rem;
}
@media only screen and (max-width: 1350px) {
  .recomendations-section .posts-wrapper {
    justify-content: center;
  }
}
.recomendations-section .post-recomendation {
  width: 30rem;
  position: relative;
}
.recomendations-section .post-recomendation img {
  width: 30rem;
  height: 30rem;
  object-fit: fill;
}
.recomendations-section .post-recomendation .background-gradient {
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  z-index: 1;
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg, rgb(2, 0, 36) 0%, rgba(255, 255, 255, 0) 100%);
}
.recomendations-section .post-recomendation .text-wrapper {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  position: absolute;
  bottom: 1.8rem;
  text-align: center;
  z-index: 2;
  color: white;
}
.recomendations-section .post-recomendation .text-wrapper h1 {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}
.recomendations-section .post-recomendation .text-wrapper span {
  font-size: 1.25rem;
}
@media only screen and (max-width: 1800px) {
  .recomendations-section .post-recomendation:nth-child(3) {
    display: none;
  }
}
@media only screen and (min-width: 1400px) {
  .recomendations-section {
    width: 140rem;
  }
}