@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');

$weights: (100, 200, 300, 400, 700, 900);

body {
  font-family: 'Quicksand', sans-serif;
  font-size: size(14);

  @include media(sm) {
    font-size: size(16);
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 900;
}

h1,
h2,
h3,
h4,
p {
  transition: .3s ease-in-out;

  @each $weight in $weights {
    &.--#{$weight}, .--#{$weight} {
      font-weight: #{$weight};
    }
  }
}

h1 {
  font-size: size(28);

  @include media(sm) {
    font-size: size(36);
  }

  @include media(xl) {
    font-size: size(48);
  }
}

h2 {
  font-size: size(36);
}

h3 {
  font-size: size(24);
}

p {
  font-size: size(14);
  font-weight: 300;

  @include media(sm) {
    font-size: size(16);
  }

  @include media(xl) {
    font-size: size(18);
  }
}
