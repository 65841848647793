/* Sessão de header do blog */
.header-section {
    max-width: 75%;
    margin: 0 auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 3.5rem;
    color: #676767;

    h1 {
        font-size: 3rem;
        font-weight: bold;
    }

    .filter-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;

        h2 {
            font-size: 2rem;
            font-weight: lighter;
        }

        div {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        select {
            outline: none !important;
            border: none !important;
            font-size: 1.25rem;
            padding: 1.15rem;
        }
    }

    @media only screen and (max-width: 1010px) {
        margin-top: 10rem;
    }
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    margin: 1.25rem 0 1.25rem 0;
}

.blog-section-anchor {
    position: absolute;
    z-index: -10;
    transform: translateY(-32rem);
}

.blog-section {
    max-width: 75%;
    margin: 0 auto 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 8rem;
    align-items: center;
    margin-top: 5rem;

    .blog-post {
        height: fit-content;
        // width: 30rem;
        // padding: 1.25rem;
        margin: 0 auto;

        img {
            // width: 30rem;
            height: 32rem;
            object-fit: cover;
        }

        .title-wrapper {
            width: 100%;
            margin-top: 2.3rem;

            span {
                font-size: 1.25rem;
                color: #676767;
            }

            h1 {
                font-size: 2rem;
                font-weight: bold;
                color: #676767;
            }
        }

        p {
            margin-top: 1rem;
            margin-bottom: 2.5rem;
            font-size: 1.1rem;
            color: #7e7e7e;
        }

        .card-divider {
            margin-bottom: 0.75rem;
        }

        .read-more-wrapper {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            img {
                height: 1.5rem;
                width: 1.5rem;
            }

            span {
                font-size: 1.2rem;
                color: #255ce8;
            }
        }
    }

    .blog-pagination {
        display: flex;
        align-items: center;
        gap: 2.5rem;
        color: #676767;
        font-size: 1.75rem;

        img {
            scale: 0.8;
        }

        div {
            display: flex;
            gap: 1.5rem;
        }

        .selected {
            color: #255ce8;
            font-weight: bold;
        }
    }

    @media only screen and (max-width: 1000px) {
        gap: 2rem;
        margin-top: 0;
    }
}

/* Sessão da pagina de um post */

.post-section {
    max-width: 75%;
    margin: 0 auto 0 auto;
    margin-top: 5rem;

    .image-wrapper {
        height: 40rem;
        width: 58rem;
        float: left;
        margin: 0 4.5rem 2.5rem 0;

        img {
            width: 58rem;
            height: 40rem;
            object-fit: cover;

            @media only screen and (max-width: 1200px) {
                width: 100%;
                height: auto;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }

        @media only screen and (max-width: 1200px) {
            float: none;
            width: 100%;
            display: flex;
            margin-right: 0;
        }
    }

    h1 {
        font-size: 2.5rem;
        margin-bottom: 1.25rem;
        color: #676767;
    }
}

.recomendations-section {
    max-width: 75%;
    margin: 0 auto 0 auto;
    margin-top: 12rem;

    h1 {
        font-size: 2.5rem;
        color: #676767;
        margin-bottom: 2rem;

        @media only screen and (min-width: 1600px) {
            margin-top: 15rem;
        }

        @media only screen and (min-width: 2150px) {
            margin-top: 25rem;
        }
    }

    .posts-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 5rem;
        margin-bottom: 10rem;

        @media only screen and (max-width: 1350px) {
            justify-content: center;
        }
    }

    .post-recomendation {
        width: 30rem;
        position: relative;

        img {
            width: 30rem;
            height: 30rem;
            object-fit: fill;
        }

        .background-gradient {
            position: absolute;
            bottom: 0;
            height: 50%;
            width: 100%;
            z-index: 1;
            background: rgb(2, 0, 36);
            background: linear-gradient(
                0deg,
                rgba(2, 0, 36, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
        }

        .text-wrapper {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
            right: 0;
            left: 0;
            position: absolute;
            bottom: 1.8rem;
            text-align: center;
            z-index: 2;
            color: white;

            h1 {
                font-size: 1.5rem;
                font-weight: bold;
                color: white;
            }

            span {
                font-size: 1.25rem;
            }
        }

        @media only screen and (max-width: 1800px) {
            &:nth-child(3) {
                display: none;
            }
        }
    }

    @media only screen and (min-width: 1400px) {
        width: 140rem;
    }
}
