.map-wrapper {
    width: 100%;
    height: 350px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .map {
        width: 100%;
        height: 100%;
        border: 0;
    }

    .--overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 2.4rem;
        color: color(--white);
        text-align: center;
        background-color: rgba(#000, 60%);
        display: grid;
        align-items: center;
        pointer-events: none;
        cursor: default;
        opacity: 0;
        -webkit-transition: opacity 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out;
        -ms-transition: opacity 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out;
        z-index: 1001;
    }
}

@include media(sm) {
    .map-wrapper {
        aspect-ratio: 3 / 2;
        width: 100%;
        height: 50vh;
    }
}

@include media(xxl) {
    .map-wrapper {
        aspect-ratio: 30 / 9;
        width: 100%;
        height: 60vh;
    }
}
