.card {
  box-shadow: 0 4px 4px rgba(#000, 25%);
  border-radius: 16px;
  padding: 16px;

  .card-content {
    padding: 24px;
  }

  .card-actions {
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid rgba(#c5c5c5, 80%);
    margin-top: 24px;
    text-align: right;
  }
}