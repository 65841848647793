/**
 * General reset
 */

[v-cloak] {
  display: none
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  overflow-y: scroll;

  /**
   * Custom scrollbar
   */
  &::-webkit-scrollbar {
    width: .8rem;
  }

  &::-webkit-scrollbar-track {
    background-color: color(--green-light-50);
  }

  &::-webkit-scrollbar-thumb {
    background-color: color(--blue);
    outline: none;
    border-radius: 0;
  }

  /**
   * Custom selection color
   */
  ::selection {
    background-color: darken(color(--blue-50), 10%);
    color: #fff;
  }
}

html,
body {
  font-size: 62.5%;
  margin: 0;
  padding: 0;
}

section {
    position: relative;

    >span {
        position: absolute;
        top: -100px;
    }
}

strong {
  font-weight: bold;
}


/**
 * Bootstrap reset
 */
img {
  -webkit-user-drag: none;

  &.px {
    image-rendering: pixelated;
  }

  &.aa {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: crisp-edges;
    image-rendering: optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
  }
}

a {
  text-decoration: none;
  color: color(--blue);
}

ul {
  list-style: none;
}

/* Animations */




/* Text-colors */
.text-white {
  color: map-get($map: $colors, $key: --white )
}

.text-grey-300 {
  color: map-get($map: $colors, $key: --grey-300 )
}



.alert {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 1000;
    width: 500px;
    max-width: 100%;
    border-radius: 8px;
    border: 1px solid #cf7a7a;
    box-shadow: 2px 13px 15px rgb(0 0 0 / 30%);

    .close {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        background-color: transparent;
        border: 0;
        color: #cf7a7a;
        text-align: center;
        line-height: 16px;
        font-size: 32px;
        cursor: pointer;

        &:hover {
            color: darken(#cf7a7a, 20%);
        }
    }
}
