.modal-theme {
  .modal-dialog {
    .modal-content {
      font-size: 1.6rem;
      padding: 1.6rem;
      border-radius: 1rem;
    }
  }
}

