// Mobile-first

#home {
    color: inherit;
}

.swiper {
    width: 100%;
    height: 250px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section-como-contratar {
    background: linear-gradient(
        107.23deg,
        #d33f44 -8.03%,
        #290563 57.48%,
        #1f42fa 136.54%
    );
    padding-top: 8rem;
    padding-bottom: 12rem;

    .description {
        margin-top: 3.5rem;

        h3 {
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 114%;
            text-transform: uppercase;
            color: #ffffff;
            @media (max-width: 575.98px) {
                font-size: 30px;
            }
        }
        p {
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 168.5%;
            /* or 25px */
            color: #ffffff;
        }
        .button-wrapper {
            @media (max-width: 575.98px) {
                margin-top: 3.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-block: 2rem;
            }
        }
        a {
            background: #265ce9;
            color: #ffffff;
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 168.5%;
            padding: 1.5rem 3.5rem;
            margin-top: 2rem;
            cursor: pointer;
            transition: all 0.2s ease;
            border: 4px solid #265ce9;

            &:hover {
                background: #1f42fa;
                border: 4px solid #1f42fa;
            }
            &:focus {
                border: 4px solid #ffffff;
            }
        }
    }
    .feature {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        .number-wrapper {
            width: 86px;
            text-align: center;
        }
        h4 {
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 700;
            color: transparent;
            font-size: 150px;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #f1eeee63;
            transform: translateX(40%);
            z-index: -1;
            @media (max-width: 767.98px) {
                transform: translateX(0);
                font-size: 100px;
            }
        }
        .img-wrapper {
            background-color: #265ce9;
            width: 109px;
            aspect-ratio: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            z-index: 2;
        }
        p {
            width: 250px;
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 114%;
            color: #ffffff;
            @media (max-width: 1024px) {
                width: 160px;
            }
            @media (max-width: 575.98px) {
                font-size: 14px;
            }
        }
    }
}

.section-plains {
    .card-plain {
        transform: translateY(-80px);
        &:nth-child(1) {
            h3 {
                color: #b1510f;
            }
        }
        &:nth-child(2) {
            h3 {
                color: #9598a0;
            }
        }
        &:nth-child(3) {
            h3 {
                color: #d7a700;
            }
        }
        &:nth-child(4) {
            h3 {
                color: #1f1f1f;
            }
        }
        &:nth-child(5) {
            h3 {
                color: #00397b;
            }
        }
        h3 {
            border-top: 1px solid #c2c2c2;
            padding-block: 1.5rem;
            margin-block: 1rem;
            font-family: 'Quicksand';
            border-bottom: 1px solid #c2c2c2;
        }
        ul {
            list-style-image: url('/img/icons/check.svg');
            padding-inline: 2rem;

            li {
                font-family: 'Quicksand';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 168.5%;
                color: #747474;
                text-transform: uppercase;
                margin-bottom: 1rem;
            }
        }
        .conteudo-text {
            padding: 16px;
            min-height: 90%;
            margin-top: -12px;
            border: 1px solid #e4e4e4;

            @media (max-width: 568px) {
                min-height: auto;
                margin-bottom: 12px;
            }
        }
    }
    .plans-footer {
        background-color: #f6f6f6;
    }
}

/* Sessão de planos VERSÃO DESKTOP */
.section-plains {
    transform: translateY(-52px);

    @media (max-width: 768.98px) {
        transform: translateY(0);
        padding-top: 2rem;
    }

    .aviso {
        padding: 10px;
        background-color: #f8f8f8;
        border: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;

        i {
            color: #79ba5b;
        }

        p {
            color: #79ba5b;
            font-weight: 400;
            font-family: 'Quicksand';
            margin-bottom: 0;
        }
    }
    .plains-grid {
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;

        .color-bronze {
            color: #b1510f;
        }

        .color-prata {
            color: #747474;
        }

        .color-gold {
            color: #d7a700;
        }

        .color-empresarial {
            color: #1f1f1f;
        }

        .color-grupo {
            color: #00397b;
        }

        .ghost {
            height: 262px;

            @media (max-width: 1199.98px) {
                height: 245px;
            }

            @media (max-width: 991.98px) {
                height: 203px;
            }
        }

        .title {
            background-color: #c2c2c22e;
            display: flex;
            justify-content: center;
            align-items: center;

            h3 {
                font-family: 'Quicksand';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;

                @media (max-width: 768.98px) {
                    font-size: 16px;
                }
            }
        }

        .subtitle {
            background-color: #c2c2c22e;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                font-family: 'Quicksand';
                font-style: normal;
                font-weight: 700;
                color: #676767;
            }
        }

        .plains-column-descriptions {
            display: flex;
            flex-direction: column;

            > p {
                padding: 10px;
                border: 1px solid #d9d9d9;
                margin-bottom: 0;
                height: 100px;
                display: flex;
                align-items: center;

                @media (max-width: 768.98px) {
                    height: 70px;
                    font-size: 11px;
                }
            }
        }

        .plains-column-default {
            text-align: center;

            @media (max-width: 991.98px) {
                img {
                    width: 103px;
                }
            }

            > div {
                padding: 10px;
                border: 1px solid #d9d9d9;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 768.98px) {
                    height: 70px;
                }

                p.p {
                    font-family: 'Quicksand';
                    font-style: normal;
                    font-weight: 700;
                    color: #5c5c5c;
                }
            }

            > div.check {
                background-color: #f3ffed;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    color: #79ba5b;
                    font-size: 25px;
                }
            }

            .valor-plano {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 10px;

                .title {
                    font-weight: 400;
                    background-color: transparent;
                    margin-bottom: 0;
                }

                .subtitle {
                    font-weight: 700;
                    background-color: transparent;
                }
            }

            .btn-contratar-plano {
                width: 100%;
                background-color: #79ba5b;
                color: white;
                font-family: 'Quicksand';
                border: none;
                padding: 10px;
                font-weight: 700;
                border: 1px solid #d9d9d9;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: #3f7625;
                }
            }
        }
    }
}

/* Sessão de planos VERSÃO Mobile */

/* Sessão do blog  */
.home-blog-section {
    height: fit-content;
    padding: 8rem 0 8rem 0;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .home-blog-section-title-wrapper {
        width: 75%;
        text-align: start;

        h1 {
            color: #676767;
            font-size: 2.5rem;
        }
    }

    .blog-posts-section {
        margin-top: 2rem;
        width: 75%;
    }

    button {
        margin-top: 8rem;
        background-color: #255ce8;
        outline: none;
        border: none;
        color: white;
        font-weight: bold;
        padding: 1.5rem 2rem 1.5rem 2rem;
    }
}

/* Sessão de posts do instagram */

.section-instagram {
    padding: 60px 0;
    background-color: #F6F9FF;

    img {
        width: 100px;
    }

    p {
        font-size: 2rem;
        color: rgba(103, 103, 103, 1);
        font-weight: bold;
    }
}

.contato-anchor {
    position: absolute;
    z-index: -999;
    transform: translateY(-15rem);
}

@media (max-width: 572px) {
    #cartao:target{
        margin-top: 75px;
    }

    #beneficios:target{
        margin-top: 140px;
    }

    #redes:target{
        margin-top: 75px;
    }

    #contratar:target{
        margin-top: 120px;
    }

    #plainsMobile:target{
       padding-top: 25px;
    }

    #parcerias:target{
        margin-top: 85px;
    }

    #trabalheConosco:target{
        margin-top: 75px;
    }

    #contato:target{
        margin-top: 75px;
    }
}
