
.swiper {
    height: 142px;
    .swiper-slide {
        background-size: cover;
        background-repeat: no-repeat;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;

        
        .swiper-img {
            object-fit: cover;
            width: 100%;
        }

        .swiper-title-wrapper {
            .title {
                font-size: 4rem;
                font-family:'hk-bold-italic';
                color: color(white-500);
            }

            .subtitle-wrapper {
                display: flex;
                flex-direction: column;
                color: color(green-300);
                font-size: 4rem;
                border-left: 2px solid #FC4547;
                padding: 0.3rem 1rem;
            }
        }            
    }

    .swiper-button-prev, .swiper-button-next {
        padding: 5rem 2.5rem;
        background-color: color(green-500);
    
        &::after {
            color: color(white-500);
            font-size: 25px;  
            
            @media (max-width: 991.98px){
                visibility: hidden;
            }
        }
    }

    .swiper-button-prev {
        left: 0;
        border-radius: 0 5px 5px 0;
    }

    .swiper-button-next {
        right: 0;
        border-radius: 5px 0 0 5px;
    }
}


.swiper.swiperMobileTableVersion {
    background-color: red;
}